.base-header
  position: fixed
  top: 0
  width: 100vw
  z-index: 100
  padding-inline: var(--margin-1)
  padding-top: 29rem
  padding-bottom: 24rem
  width: 100vw
  background: var(--color-white-100)
  +gap(right, 30rem)
  justify-content: space-between
  align-items: center
  box-shadow: 0 4rem 6rem rgba(var(--color-black-100-rgb), 0.1)
  +sp-view
    padding-top: 0
    padding-bottom: 0
    padding-inline: var(--margin-2)
    height: 70rem
    &::after
      content: ""
      position: absolute
      top: 0
      left: 0
      background: var(--color-white-100)
      z-index: -1
      display: block
      width: 100vw
      height: 70rem
  .logo
    width: 197rem
    height: auto
    +sp-view
      width: 110rem
  .logo-by
    width: 134rem
    margin-left: 18rem
    +sp-view
      width: 106rem
      margin-left: 12rem

  .base-header__top-copy
    +text(16rem, bold)
    transform: translateY(12rem)
    display: inline-block
    margin-right: auto
    position: relative
    top: -3rem
    span
      display: inline-block
      width: fit-content
      text-align: center
      position: relative
      padding-top: .4em
      background-position: top left -2rem
      background-repeat: repeat-x
      background-size: 1em 3px
      --dot-color: var(--color-black-100)
      background-image: radial-gradient(1.5px 1.5px at center center,var(--dot-color),var(--dot-color) 100%,transparent)
        
  .base-header__button
    width: 174rem
    height: 54rem
    background: var(--color-pink-100)
    color: var(--color-white-100)
    +flex-middle
    letter-spacing: 0.01em
    border-radius: 10rem
    box-shadow: inset 0 4rem 6rem rgba(var(--color-white-100-rgb), 0.53)

    +text(14rem, bold)
  .base-header__menu-button
    +size(24rem)
    position: relative
    overflow: hidden
    svg
      +absolute-middle
      width: 24rem
      height: auto
    .icon_close
      transform: translateY(-100%)
  .base-header__menu
    width: 100%
    height: 100vh
    position: absolute
    top: 0
    background: var(--color-black-100)
    left: 0
    z-index: -1
    color: var(--color-white-100)
    transform: translateY(-100%)
  .base-header__menu__wrap
    +gap(bottom, 36rem)
    padding-inline: var(--margin-3)
    padding-top: 160rem
  // .base-header__menu__item
  .base-header__menu__title
    +text(38rem, regular, $family-class)
  .base-header__menu__sub-title
    +text(12rem, medium)
    margin-top: 5rem
  .base-header__menu__copy
    +text(10rem, regular, $family-class)
    text-align: center
    width: 100vw
    display: inline-block
    position: absolute
    bottom: 42rem

  // transition
  .base-header__button span
    transition: transform 1s cubic-bezier(0.58, 0.14, 0, 1)
  // .base-header__menu
  //   transition: transform 1s cubic-bezier(0.58, 0.14, 0, 1)

  // animation
  .base-header__button:hover
    span
      transform: scale(1.1)
  // &[menu]
  //   .base-header__menu
  //     transform: translateY(0)