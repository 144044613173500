$sp-size: "(max-width: 750px)"
$pc-size: "(min-width: 751px)"
$pc-sm-size: "(max-width: 992px)"
$pc-md-size: "(max-width: 1200px)"
$pc-lg-size: "(max-width: 1580px)"

$sp-over-size: "(min-width: 751px)"

$pc-sm-over-size: "(min-width: 993px)"
$pc-md-over-size: "(min-width: 1201px)"
$pc-lg-over-size: "(min-width: 1581px)"

$pc-sm-only-size: "(min-width: 751px) and (max-width: 992px)"
$pc-md-only-size: "(min-width: 993px) and (max-width: 1200px)"
$pc-lg-only-size: "(min-width: 1201px) and (max-width: 1580px)"



=custom-view($size)
  +respond-to("(max-width: #{$size})")
    @content

=respond-to($media)
  @media #{$media}
    @content

=sp-view
  +respond-to($sp-size)
    @content

=tablet-size
  +respond-to("(max-width: 770px)")
    @content

=pc-view
  +respond-to($pc-size)
    @content

=pc-sm-view
  +respond-to($pc-sm-size)
    @content
=pc-md-view
  +respond-to($pc-md-size)
    @content
=pc-lg-view
  +respond-to($pc-lg-size)
    @content

=sp-view-over
  +respond-to($sp-over-size)
    @content

=pc-sm-view-over
  +respond-to($pc-sm-over-size)
    @content
=pc-md-view-over
  +respond-to($pc-md-over-size)
    @content
=pc-lg-view-over
  +respond-to($pc-lg-over-size)
    @content

=pc-sm-view-only
  +respond-to($pc-sm-only-size)
    @content
=pc-md-view-only
  +respond-to($pc-md-only-size)
    @content
=pc-lg-view-only
  +respond-to($pc-lg-only-size)
    @content

=ie-view
  @media all and (-ms-high-contrast: none)
    @content

=safari-view
  _::-webkit-full-page-media, _:future, :root &
    @content

=hover
  @media(hover: hover)
    @content

=old-safari
  @supports not (aspect-ratio: 16/9)
    @content
    
=not-last
  &:not(&:last-child)
    @content

=no-scrollbar
  -ms-overflow-style: none
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none 

=box-sizing
  -webkit-box-sizing: border-box
  -moz-box-sizing:    border-box
  box-sizing:         border-box

=margin-gap($direction: right, $num:1rem, $childType: last-child)
  gap: 0px
  & > *:not(:#{$childType})
    @if $direction == right
      margin-right: $num
    @else if $direction == bottom
      margin-bottom: $num

=gap($direction: right, $num: 1rem, $reverce: none)
  display: flex
  --gap: #{$num}
  @if $direction == right
    @if $reverce == reverse
      flex-direction: row-reverse
      @if $num != 0rem and $num != 0px
        gap: var(--gap)
        @supports not (aspect-ratio: 16/9)
          +margin-gap(right, var(--gap), first-child)
        +ie-view
          +margin-gap(right, var(--gap), first-child)
    @else
      flex-direction: row
      @if $num != 0rem and $num != 0px
        gap: var(--gap)
        @supports not (aspect-ratio: 16/9)
          +margin-gap(right, var(--gap), last-child)
        +ie-view
          +margin-gap(right, var(--gap), last-child)

  @else if $direction == bottom
    @if $reverce == reverse
      flex-direction: column-reverse
      @if $num != 0rem and $num != 0px
        gap: var(--gap)
        @supports not (aspect-ratio: 16/9)
          +margin-gap(bottom, var(--gap), first-child)
        +ie-view
          +margin-gap(bottom, var(--gap), first-child)

    @else
      flex-direction: column
      @if $num != 0rem and $num != 0px
        gap: var(--gap)
        @supports not (aspect-ratio: 16/9)
          +margin-gap(bottom, var(--gap), last-child)
        +ie-view
          +margin-gap(bottom, var(--gap), last-child)

=full-size
  height: 100%
  width: 100%

=full-screen
  height: 100vh
  width: 100vw

=fit-size
  height: fit-content
  width: fit-content

=flex-middle
  display: flex
  justify-content: center
  align-items: center

=absolute-middle
  position: absolute
  inset: 0
  margin: auto

=default-width($margin:150px,$width:1500px)
  max-width: $width
  width: 100%
  left: 0
  right: 0
  margin-left: auto
  margin-right: auto
  box-sizing: border-box
  padding-left: $margin
  padding-right: $margin
  +pc-sm-view
    padding-left: 80px
    padding-right: 80px
  +sp-view
    max-width: 100%
    padding-left: 24px
    padding-right: 24px

=default-padding($margin)
  width: 100vw
  padding-left: $margin
  padding-right: $margin
  +pc-sm-view
    padding-left: 24rem
    padding-right: 24rem

=default-max($margin: var(--margin-2))
  max-width: calc(100vw - $margin * 2)
  width: 100%
  margin-left: auto
  margin-right: auto

=text-outline($color: var(--color-dark-blue))
  color: var(--color-cream)
  -webkit-text-stroke: 1px $color
  -webkit-text-fill-color: transparent

=image-crop
  width: auto
  height: auto
  max-width: 100%
  max-height: 100%

=test-border($color: red)
  border: 1px solid $color

=size($width: auto, $height: $width)
  width: $width
  height: $height
=fix-size($width: auto, $height: $width)
  width: $width
  height: $height
  max-width: $width
  max-height: $height
  min-width: $width
  min-height: $height
=text-overflow($num: 1)
  text-overflow: ellipsis
  overflow: hidden
  @if $num == 1
    white-space: nowrap
  @if $num != 1
    display: -webkit-box
    -webkit-line-clamp: $num
    -webkit-box-orient: vertical
    text-overflow: ellipsis

=text-inherit
  font-size: inherit
  font-family: inherit
  font-weight: inherit
  line-height: inherit
  color: inherit
  letter-spacing: inherit

=aspect-ratio($width, $height, $max-width: none)
  position: relative
  @if ($max-width != 'none')
    max-width: $max-width + px
  &::before
    float: left
    content: ""
    padding-top: calc(($height / $width) * 100% * 100 / 100)
  &::after
    display: block
    content: ""
    clear: both
  > *
    position: absolute
    inset: 0
    width: 100%
    height: 100%
    object-fit: cover

=button-height($height, $top: 0rem)
  padding-top: $top
  &:before
    content: ""
    display: inline-block
    height: calc($height - $top)
    padding-top: $top
    vertical-align: middle

=hover-button($diff: white)
  cursor: pointer
  transition: background 0.5s ease, color 0.5s ease, border 0.5s ease,
  &:hover
    background: var(--color-white-100)
    color: var(--color-blue-100)
    border: 1rem solid var(--color-blue-100)
    @if $diff == blue
      background: var(--color-blue-100)
      color: var(--color-white-100)
      border: 1rem solid var(--color-white-100)
    
    
=sp-max-height
  height: 100vh
  max-height: 880px

=head-dot($size: 4rem)
  &:after
    content: ''
    display: block
    background: var(--color-black-100)
    +size($size)
    position: absolute
    top: 0
    bottom: 0
    left: 0
    margin: auto
    border-radius: 100%
=side-dot
  position: relative
  width: fit-content
  &:after, &:before
    content: ''
    display: block
    background: var(--color-black-100)
    +size(4rem)
    position: absolute
    top: 0
    bottom: 0
    margin: auto
    --gap: 45rem
    border-radius: 100rem
  &::before
    left: calc(-1 * var(--gap))
  &::after
    right: calc(-1 * var(--gap))
=max-width-setting($width: var(--max-width))
  max-width: $width
  width: 100%
  margin-inline: auto