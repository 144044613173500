.base-footer
  background: var(--color-black-100)
  .base-footer__wrap    
    color: var(--color-white-100)
    padding-inline: var(--margin-2)
    padding-top: 111rem
    padding-bottom: 145rem
    position: relative
    display: flex
    justify-content: space-between
    +max-width-setting
    +sp-view
      padding-inline: var(--margin-3)
      +gap(bottom, 38rem)
  .base-footer__top-button
    +text(18rem, regular, $family-class)
    +gap(right, 20rem)
    align-items: center
    position: absolute
    top: 96rem
    right: 82rem
    +sp-view
      right: var(--margin-2)
      top: 31rem
      font-size: 18rem
  .base-footer__top-button__circle
    +size(46rem)
    border-radius: 100rem
    background: var(--color-white-100)
    +flex-middle
  .base-footer__top
    +gap(bottom, 40rem)
    --width: 198rem
    +sp-view
      --gap: 28rem
  .logo
    width: var(--width)
    height: auto
    path
      fill: var(--color-white-100)
      width: 176rem
  .base-footer__top__button
    width: var(--width)
    border: 1px solid var(--color-white-100)
    height: 54rem
    +flex-middle
    +text(14rem, medium)
    +sp-view
      width: 153rem
      height: 40rem
    
  .base-footer__nav
    +gap(right, 70rem)
    width: calc(50vw - var(--margin-2) - 18rem)
    +sp-view
      width: 100%
      --gap: 42rem
  .base-footer__nav__list
    +gap(bottom, 30rem)
  .base-footer__nav__item
    +text(14rem, medium)
    +size(fit-content)
    +gap(right, 8rem)
    align-items: baseline
    svg path
      fill: var(--color-white-100)
  .base-footer__copy
    +text(12rem, regular, $family-class)
    +size(fit-content)
    text-align: center
    position: absolute
    inset: 0
    bottom: 21rem
    top: auto
    margin-inline: auto
    +sp-view
      font-size: 10px
      position: static
      padding-top: calc(93rem - var(--gap))
    
  // transition
  .base-footer__top__button
    transition: all 0.3s ease
  .base-footer__top-button
    .base-footer__top-button__circle
      transition: all 0.3s ease
      border: 1px solid var(--color-white-100)
      svg path
        transition: all 0.3s ease

  // animation
  .base-footer__top__button:hover
    background: var(--color-white-100)
    color: var(--color-black-100)
  .base-footer__top-button:hover
    .base-footer__top-button__circle
      background: var(--color-black-100)
      svg path
        stroke: var(--color-white-100)
