:root
  --margin-1: 40rem
  --margin-2: 80rem
  --margin-3: 158rem
  --margin-4: 300rem
  +pc-sm-view
    --margin-1: 10rem
    --margin-2: 22rem
    --margin-3: 38rem
    --margin-4: 54rem
  
html
  min-height: 100%
  font-feature-settings: "palt" 1
  font-size: calc(100 / var(--base-vw) * 1 * 1vw)
  --max-width: 1580px
  +pc-sm-view-over
    --base-vw: 1440
  +pc-sm-view 
    --base-vw: 992
  +sp-view
    --base-vw: 428
  +pc-lg-view-over
    font-size: 1px
    
html,body
  max-width: 100vw
  color: var(--color-blue-100)
  ::selection
    background: var(--select-fill-color)
    color: var(--select-text-color)
  ::-moz-selection
    background: var(--select-fill-color)
    color: var(--select-text-color)
  

body
  color: var(--color-black-100)
  font-family: $family

span, div
  +text-inherit

img
  object-fit: cover
  pointer-events: none
  touch-callout: none
  user-select: none
  user-drag: none
  border-radius: inherit

video
  max-width: 100vw
  ovject-fit: cover

picture
  display: block
  source,img
    width: 100%
    height: 100%

a
  cursor: pointer
  line-height: 1

p
  text-align: justify

button
  position: relative
  width: fit-content
  &::after
    content: ""
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    height: calc(100% + 22px)
    width: calc(100% + 22px)

section
  width: 100vw

.p-index
  min-height: 100vh
  position: relative
  max-width: 100vw
  overflow-x: hidden
  background: var(--color-white-100)

.index
  position: fixed
  inset: 0
  overflow: hidden
  overscroll-behavior: none


[pc-only]
  +pc-sm-view
    display: none !important
[sp-only]
  +pc-sm-view-over
    display: none !important


[opening]
  opacity: 0

[line-over]
  position: relative
  display: inline-block
  +size(fit-content)
  .line-over__span
    content: ""
    position: absolute
    left: 0
    width: 100%
    height: 1px
    bottom: -1px
    background: var(--color-white-100)
    transform: scaleX(0)
    transform-origin: left
    

    