@function fvw($fs: 14, $base: 1366)
  $no_unit_size: calc($fs / ($fs * 0 + 1px))
  $base_size: calc(100 / $base)
  @return $base_size * $no_unit_size * 1vw

$family: dnp-shuei-gothic-gin-std, sans-serif
$family-class: Classico, $family
$weightMap: (regular: 400,medium: 500,bold: 700,black: 900) 

@font-face 
  font-family: Classico
  src: url("../../font/Classico.otf") format("opentype")
  font-weight: 400


=text($fs:14rem, $weight:regular,  $fam:$family)
  font-size: $fs
  font-family: $fam
  font-weight: map-get($weightMap, $weight)
  -webkit-font-smoothing: antialiased
  @if $fam == $family
    letter-spacing: 0.05em
