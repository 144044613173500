.tutorial
  padding: 0 200rem
  +gap(bottom, 10rem)
  flex-direction: column
  inset: 0
  margin: auto
  h1
    text-transform: uppercase
    +text(27rem, bold)
    opacity: 1
  h2
    font-size: 20rem
    margin-top: 20rem
  p
    font-size: 12rem
    line-height: 1.8
    letter-spacing: 0.05em
  pre
    border-radius: 3px
    background: rgb(247, 246, 243)
    padding: 34px 16px
    width: 100%
    color: rgb(55, 53, 47)
    white-space: pre-wrap
    word-break: break-all
    +text(12rem, regular)
    line-height: 1.4
    code
      display: inline-block
  code
    width: 100%
    border-radius: 3px
    background: rgb(247, 246, 243)
    +text(12rem, regular)
    padding: 2rem 8rem
