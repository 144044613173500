.parts-title
  +gap(bottom, 12rem)
  +sp-view
    --gap: 16rem
  .parts-title__main
    +text(65rem, regular, $family-class)
    +sp-view
      font-size: 40rem
  .parts-title__sub
    +text(18rem, medium, $family)
    +sp-view
      font-size: 14rem