html
  --color-black-100: #000000
  --color-white-100: #ffffff
  --color-gray-100: #F2F2F2
  --color-gray-200: #EAEAEA
  --color-gray-300: #BABABA
  --color-pink-100: #ef7777

  --color-black-100-rgb: 0, 0, 0
  --color-white-100-rgb: 255, 255, 255
  --color-gray-100-rgb: 248, 248, 248
  --color-gray-200-rgb: 234, 234, 234
  --color-pink-100-rgb: 230, 145, 145
  
  --select-fill-color: var(--color-black-100)
  --select-text-color: var(--color-white-100)