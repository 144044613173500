.parts-nav
  position: fixed
  z-index: 5
  +max-width-setting(calc(100vw - var(--margin-1) * 2))
  color: var(--color-white-100)
  background: var(--color-black-100)
  bottom: 10rem
  left: 0
  right: 0
  display: inline-block
  text-align: center
  line-height: 51rem
  +text(14rem, bold)
  background: var(--color-pink-100)
  border-radius: 10rem
  box-shadow: inset 0 4rem 6rem rgba(var(--color-white-100-rgb), 0.53)