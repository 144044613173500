[top-page]
  .first
    width: 100vw
    height: 100vh
    max-height: 900rem
    padding-top: 107rem
    overflow: hidden
    position: relative
    +sp-view
      padding-top: 70rem
      --border-pos: 151rem
    .first__belt
      width: 100vw
      height: 48rem
      background: var(--color-black-100)
      color: var(--color-white-100)
      padding-inline: var(--margin-1)
      display: flex
      justify-content: space-between
      align-items: center
      +text(11rem, medium)
      letter-spacing: 0.1em
      +sp-view
        white-space: nowrap
        +gap(right, var(--margin-2))
        padding-inline: 22px
        height: 33rem
    .first__nav
      +gap(right, 40rem)
    .first__button
      +gap(right, 8rem)
      align-items: center
    .first__button__circle
      +size(22rem)
      background: var(--color-white-100)
      border-radius: 100rem
      +flex-middle
    .first__wrap
      height: calc(100% - 48rem - 80rem)
      +sp-view-over
        position: relative
      +sp-view
        height: calc(100% - (var(--border-pos) + 140rem))
    .first__slide
      display: flex
      height: 100%
    .first__slide__column
      display: flex
      +size(100%)
    .first__slide__item
      height: 100%
      width: 100%
    .first__title
      position: absolute
      bottom: 0
      left: 0
      +text(160rem, regular, $family-class)
      color: rgba(var(--color-black-100-rgb), 0.17)
      
      // &--grad
      //   background: radial-gradient(20% 70% at 12% 50%, #000000 0%, rgba(0, 0, 0, 0.17) 100%)
      //   -webkit-background-clip: text
      //   -webkit-text-fill-color: transparent
      //   background-clip: text
      //   text-fill-color: transparent
      +sp-view-over
        transform: translateY(calc(50% + 3rem))
      +sp-view
        font-size: 97rem
        line-height: 70rem
        bottom: var(--border-pos)
    // .first__title__text
    
    .first__title__text div
        --parcent-x: -60%
        --parcent-y: 40%
        background: radial-gradient(80% 50% at var(--parcent-x) var(--parcent-y), #fafafa 0%, rgba(0, 0, 0, 0.17) 100%)
        -webkit-background-clip: text
        -webkit-text-fill-color: transparent
        background-clip: text
        text-fill-color: transparent

    .first__title__tips
      +text(14rem, bold)
      position: absolute
      top: 45rem
      right: 18rem
      display: inline-block
      color: var(--color-black-100)
      line-height: 1
      span
        display: inline-block
        width: fit-content
        text-align: center
        position: relative
        padding-top: .4em
        background-position: top left -2rem
        background-repeat: repeat-x
        background-size: 1em 3px
        --dot-color: var(--color-black-100)
        background-image: radial-gradient(1.5px 1.5px at center center,var(--dot-color),var(--dot-color) 100%,transparent,transparent)
    // // transition
    // .first__button
    //   span
    //     transition: opacity 0.5s ease
    //   .first__button__circle
    //     transition: transform 0.5s ease
    // // animation
    // .first__button:hover
    //   span
    //     opacity: 0.7
    //   .first__button__circle
    //     transform: scale(1.1)
    //   // animation: first-title 1.5s ease 0.5s forwards
    
  .about
    margin-top: 77rem
    margin-inline: auto
    +gap(bottom, 67rem)
    +sp-view
      margin-top: -80rem
    .about__section
      display: flex
      justify-content: space-between
      align-items: center
      +max-width-setting
      +sp-view
        flex-direction: column
        +gap(bottom, 37rem)

    .about__info
      position: relative
      +sp-view
        +max-width-setting(calc(100vw - 2 * var(--margin-3)))
    .about__circle
      position: absolute
      width: 200rem
      top: 0
      opacity: 0.17
      +sp-view
        --width: 110rem
        width: var(--width)
        height: auto
        // right: calc(var(--margin-4) - var(--margin-3))
        top: 50%
        right: calc(var(--width) * -0.5)
        transform: translateY(-50%)
        
    .about__title
      +text(26rem, bold)
      +sp-view
        font-size: 20rem
        line-height: 1.6
        width: fit-content
        position: relative
    .about__message
      +text(14rem, medium)
      line-height: 2.2
      margin-top: 54rem   
      +sp-view
        font-size: 12rem
        margin-top: 19rem
        line-height: 2.3
    .about__message__wrap
      +gap(bottom, 9rem)
      margin-top: 54rem
      +sp-view
        margin-top: 19rem
    .about__message__text 
      +text(14rem, medium)
      +gap(right, 13rem)
      align-items: baseline
      line-height: normal
    .about__image
      max-width: 703rem
      width: 100%
      height: 516rem
      position: relative
      +sp-view
        height: auto
      
    .about__message__circle
      +size(24rem)
      border: 1px solid var(--color-black-100)
      +flex-middle
      // border-radius: 100%
      +sp-view
        +fix-size(22rem)
        min-width: 24rem
        position: relative
        top: 6rem
        svg
          +absolute-middle
    .about__image__contents, .about__image__shadow
      width: calc(100% - 36rem)
      height: calc(100% - 25rem)
      +sp-view
        height: 272rem
        width: calc(100vw - var(--margin-4))
    .about__image__contents
      position: relative
      display: block
      z-index: 2
    .about__image__shadow
      position: absolute
      bottom: 0
      z-index: 1
      +sp-view
        bottom: -15rem
        right: var(--margin-3)
      
    +sp-view-over
      .about__section:nth-child(odd)
        flex-direction: row-reverse
        .about__info
          padding-left: 125rem
          padding-right: 194rem
          top: -28rem
        .about__circle
          right: 149rem
          top: -114rem
        .about__image__shadow
          right: 0
          
      .about__section:nth-child(even)
        .about__info
          padding-left: 179rem
          top: 26rem
        .about__circle
          left: 76rem
          top: -129rem
        .about__image__contents
          margin-left: auto
        .about__image__shadow
          left: 0
    +sp-view
      .about__section:nth-child(even)
        .about__image__contents
          margin-left: auto
        .about__image__shadow
          right: auto
          left: var(--margin-3)

  .why
    margin-top: 100rem
    background: var(--color-gray-100)
    min-height: 100vh
    padding-top: 120rem
    padding-bottom: 120rem
    border-top-left-radius: 80rem
    border-top-right-radius: 80rem
    +sp-view
      border-top-left-radius: 60rem
      border-top-right-radius: 60rem
      margin-top: 72rem
      padding-bottom: 54rem
      padding-top: 56rem
    .parts-title
      .parts-title__main
        +side-dot
        margin-inline: auto
      span
        text-align: center
    .why__list
      margin-inline: auto
      
      margin-top: 48rem
      width: fit-content
      +sp-view-over
        display: grid
        grid-template-columns: repeat(3, 300rem)
        grid-template-rows: repeat(2, 1fr)
        gap: 40rem 80rem
      +sp-view
        +gap(bottom, 62rem)
        margin-top: 61rem
    .why__item
      +sp-view
        // +test-border
        display: flex
        flex-direction: column
        padding-inline: var(--margin-3)

    .why__item__image
      width: 100%
      height: auto
      +sp-view
        width: calc(100% - 20rem)
        margin: auto
        display: block
        order: 3
        margin-bottom: 20rem
        // background: var(--color-white-100)
        // border-radius: 10rem
        // border: 1px solid rgba(var(--color-pink-100-rgb), 0.1)
    .why__header
      +text(16rem, regular, $family-class)
      padding-bottom: 6rem
      // border-bottom: 1px solid var(--color-gray-200)
      margin-top: 28rem
      display: flex
      justify-content: space-between
      align-items: baseline
      & > span:first-child
        position: relative
        &::after
          content: ""
          display: inline-block
          position: absolute
          width: 108rem
          height: 44rem
          border-radius: 100%
          top: 50%
          left: 50%
          transform: translateY(-50%) translateX(-50%) rotate(-5deg)
          margin: auto
          border: 1px solid rgba(var(--color-black-100-rgb), 0.2)
      +sp-view
        order: 1
    .why__item__title
      margin-top: 20rem
      line-height: 1.6
      +text(18rem, bold)
      color: var(--color-pink-100)
      border-bottom: 1px solid rgba(var(--color-black-100-rgb), 0.2)
      padding-bottom: 14rem
      +sp-view
        font-size: 18rem
        margin-top: 15rem
        padding-bottom: 12rem
        order: 2
        border-bottom: none
    .why__link
      +text(12rem, medium)
      +gap(right, 3rem)
      align-items: center
      span
        text-decoration: underline
        text-underline-offset: 4rem
      +sp-view
        font-weight: bold
    .why__item__message
      +text(12rem, medium)
      margin-top: 16rem
      line-height: 1.8
      +sp-view
        margin-top: 13rem
        order: 4
    .why__scene
      width: calc(100vw - var(--margin-3) * 2)
      margin-inline: auto
      background: var(--color-gray-200)
      margin-top: 100rem
      padding-top: 60rem
      padding-bottom: 80rem
      +sp-view
        padding-bottom: 38rem
        padding-top: 30rem
        margin-top: 62rem
    .why__scene__title
      +text(18rem, medium)
      margin-inline: auto
      +side-dot
      +sp-view
        line-height: 1.75
        text-align: center
    .why__scene__list
      width: fit-content
      margin-inline: auto
      display: grid
      grid-template-columns: repeat(4, 180rem)
      grid-template-rows: repeat(2, 1fr)
      gap: 20rem 20rem
      margin-top: 44rem
      +sp-view
        grid-template-columns: repeat(2, 1fr)
        grid-template-rows: repeat(4, 1fr)
        width: 100%
        padding-inline: 16rem
        gap: 12rem
        margin-top: 32rem
    .why__scene__text
      +flex-middle
      height: 40rem
      background: var(--color-white-100)
      +text(14rem, medium)
      border-radius: 4rem
      span
        +size(fit-content)
        display: inline-block
      +sp-view
        height: 30rem
        font-size: 12rem
  .plans
    padding-inline: var(--margin-3)
    padding-top: 115rem
    padding-bottom: 120rem
    max-width: var(--max-width)
    width: 100%
    margin-inline: auto
    +sp-view-over
      display: flex
      justify-content: space-between
      align-items: flex-start
    +sp-view
      padding-top: 54rem
      .parts-title
        .parts-title__main
          +side-dot
          margin-inline: auto
        span
          text-align: center
      padding-bottom: 0rem
    .plans__section-wrap
      max-width: 700rem
      width: 100%
      padding-top: 23rem
      +gap(bottom, 103rem)
      +sp-view
        padding-top: 40rem
    .plans__header
      +gap(right, 21rem)
      padding-bottom: 22rem
      
      +sp-view-over
        +gap(right, 21rem)
        border-bottom: 1px solid var(--color-gray-200)
      +sp-view
        +gap(bottom, 20rem)
        align-items: center
        position: relative
        &:after
          content: ''
          display: block
          +size(100%)
          position: absolute
          bottom: 0
          left: 0
          border: 1px solid rgba(var(--color-gray-100-rgb), 0.8)
          height: calc(100% - 30rem)
          background: var(--color-white-100)
          z-index: 0
          box-shadow: 0px 9px 76px rgba(63, 63, 63, 0.11), 0px 2.01027px 16.9756px rgba(63, 63, 63, 0.0718408), 0px 0.598509px 5.05408px rgba(63, 63, 63, 0.045684)

          
    .plans__header__number
      +size(var(--size))
      min-width: var(--size)
      --size: 54rem
      background: var(--color-black-100)
      border-radius: 100rem
      +gap(bottom, 2rem)
      +flex-middle
      color: var(--color-white-100)
      & > span:nth-child(1)
        +text(10rem, regular, $family-class)
        margin-top: 4rem

      & > span:nth-child(2)
        +text(22rem, regular, $family-class)
      +sp-view
        --size: 60rem
        z-index: 2
        position: relative
    .plans__header__info
      width: 100%
      position: relative
      z-index: 1
      +sp-view-over
        padding-top: 14rem
      
    .plans__header__title
      +text(24rem, medium)
      color: var(--color-pink-100)
      +sp-view
        font-size: 18rem
      span
        +text(14rem, medium)
      +sp-view
        text-align: center
    .plans__header__message
      +text(12rem, medium)
      line-height: 2
      margin-top: 12rem
      +sp-view
        text-align: center
        margin-top: 16rem
    .plans__body
      margin-top: 30rem
      +sp-view
        margin-top: 40rem
    .plans__body__header
      +text(18rem, bold)
      padding-left: 18rem
      position: relative
      margin-bottom: 18rem
      +head-dot(4rem)
      +sp-view
        font-size: 16rem
    // .plans__feature
    .plans__flow__wrap
      +gap(right, 24rem)
      margin-top: 35rem
      +sp-view
        --gap: 0

    .plans__flow__graph
      display: flex
      flex-direction: column
      +sp-view-over
        justify-content: space-between
    .plans__flow__graph-item
      +sp-view
        height: var(--height)
    // .plans__flow__image

      
    .plans__feature__wrap
      +text(12rem, medium)
      padding: 22rem 27rem
      background: var(--color-gray-100)
      width: 100%
      list-style-type: disc
      list-style-position: inside
      +gap(bottom, 8rem)

    .plans__flow
      margin-top: 23rem
    .plans__flow__list
      & > .plans__flow__item:last-child
        .plans__flow__info
          padding-bottom: 0rem !important
        .plans__flow__num-bar
          display: none
    .plans__flow__item
      +gap(right, 18rem)
    .plans__flow__info
      width: 306rem
      padding-bottom: 44rem
      // display: flex
      +sp-view
        width: 100%
    .plans__flow__image
      width: 117rem
      height: auto
    .plans__flow__num
      // +flex-middle
      display: flex
      align-items: center
      flex-direction: column

    .plans__flow__num-circle
      +size(var(--size))
      min-height: var(--size)
      --size: 45rem
      background: var(--color-gray-100)
      border: 1px solid var(--color-gray-200)
      border-radius: 100rem
      +flex-middle
      +text(15rem, regular, $family-class)
      line-height: 1
      span
        margin-top: 2rem
      +sp-view
        --size: 38rem
        font-size: 14rem
    .plans__flow__num-bar
      height: 100%
      width: 1px
      background: var(--color-gray-200)
    
    .plans__flow__header
      +gap(bottom, 9rem)
      padding-bottom: 6rem
      border-bottom: 1px solid var(--color-gray-200)
      color: var(--color-pink-100)
      & > span:nth-child(1)
        +text(14rem, bold)
      & > span:nth-child(2)
        +text(12rem, bold)
        +sp-view
          line-height: 1.6
      +sp-view
        --gap: 6rem
        padding-bottom: 8rem
    .plans__flow__body
      margin-top: 8rem
      +text(12rem, medium)
      line-height: 1.8
    .plans__flow__notice
      +text(10rem, medium)
      margin-top: 10rem
      +sp-view
        line-height: 1.4
  .price
    margin-top: 100rem
    background: var(--color-gray-100)
    min-height: 100vh
    padding-top: 120rem
    padding-bottom: 120rem
    border-top-left-radius: 80rem
    border-top-right-radius: 80rem
    --max-width: 960rem
    --child-head-width: 440rem
    +sp-view
      border-top-left-radius: 60rem
      border-top-right-radius: 60rem
      margin-top: 60rem
      padding-bottom: 54rem
      padding-top: 53rem
      --child-head-width: auto
      
    .parts-title
      .parts-title__main
        +side-dot
        margin-inline: auto
      span
        text-align: center
    .price__list
      max-width: var(--max-width)
      width: 100%
      +gap(bottom, 40rem)
      align-items: center
      margin-inline: auto
      margin-top: 58rem
      +sp-view
        --gap: 32rem
    .price__item
      max-width: var(--max-width)
      width: 100%
      height: 300rem
      
      display: flex
      background: var(--color-white-100)
      +sp-view
        flex-direction: column
        height: auto
        width: calc(100vw - var(--margin-2) * 2)
        padding: 32rem
    .price__item__tips
      +text(11rem, bold)
      color: var(--color-pink-100)
      line-height: 1.8
      // +sp-view-over
      //   position: absolute
      //   bottom: 38rem
    .price__img
      width: var(--child-head-width)
      height: 100%
      +sp-view-over

        padding: 38rem 60rem
    .price__img__contents
      +size(100%)
    .price__info
      width: calc(100% - var(--child-head-width))
      display: flex
      flex-direction: column
      padding-bottom: 38rem
      padding-right: 60rem
      +gap(bottom, 20rem)
      padding-top: calc(38rem + 20rem)
      position: relative
      +sp-view
        width: 100%
        padding: 0rem
        padding-top: 16rem
    .price__info__item-bar
      width: 100%
      height: 1px
      background: var(--color-gray-200)
      // margin-top: 40rem
    .price__info__item
      display: flex
      justify-content: space-between
      flex: 1
      // margin-top: 20rem
    .price__info__title
      +text(18rem, bold)
      padding-left: 22rem
      position: relative
      +head-dot(4rem)
      height: fit-content
      position: relative
      +sp-view
        font-size: 16rem
        padding-left: 17rem
        span
          white-space: nowrap
          display: inline-block
          width: 100%
        // white-space: nowrap
        &:after
          top: 7rem
          bottom: auto
    .price__info__title-notice
      font-size: 12rem
      white-space: nowrap
      +sp-view-over
        position: absolute
        bottom: -20rem
        left: 22rem
      +sp-view
        font-size: 10rem
        position: relative
        left: -17rem
    .price__info__price-list
      +gap(bottom, 15rem)
    .price__info__price
      text-align: right
      +text(20rem, medium)
      span
        font-size: 16rem
      +sp-view
        font-size: 16rem
        white-space: nowrap
        span
          font-size: 12rem
    .price__bottom
      +max-width-setting
      margin-top: 80rem
      --gap: 40rem
      +sp-view-over
        +gap(right, 40rem)
      +sp-view
        +gap(bottom, 30rem)
        +max-width-setting(calc(100vw - var(--margin-2) * 2))
        margin-top: 40rem
    .price__bottom-item
      width: auto
      border: 1px solid var(--color-black-100)
      background: var(--color-white-100)
      +gap(bottom, 0rem)
      &:first-child
        width: var(--child-head-width)
      &:last-child
        width: calc(100% - var(--child-head-width) + var(--gap))
      +sp-view
        border-color: var(--color-gray-300)
    .price__bottom-item__head
      width: 100%
      height: 64rem
      border-bottom: 1px solid var(--color-black-100)
      +flex-middle
      background: var(--color-gray-100)
      +sp-view
        border-color: var(--color-gray-300)
        height: 40rem
    .price__bottom-item__title
      margin-inline: auto
      position: relative
      width: fit-content
      +text(20rem, bold)
      &:after, &:before
        content: ''
        display: block
        background: var(--color-black-100)
        +size(4rem)
        position: absolute
        top: 0
        bottom: 0
        margin: auto
        --gap: 45rem
        border-radius: 100rem
      &::before
        left: calc(-1 * var(--gap))
      &::after
        right: calc(-1 * var(--gap))
      +sp-view
        font-size: 16rem
    .price__bottom-item__body
      padding: 14rem 48rem
      +gap(bottom, 16rem)
      height: 100%
      justify-content: center
      +sp-view
        padding: 22rem 32rem
    .price__bottom-item__description
      display: flex
      justify-content: space-between
      align-items: baseline
    .price__bottom-item__sub-title
      +text(14rem, bold)
      +sp-view
        font-size: 12rem
    .price__bottom-item__tips
      +text(16rem, medium)
      span
        font-size: 12rem
      +sp-view
        font-size: 14rem
  .gallery
    padding-top: 120rem
    .parts-title
      .parts-title__main
        +side-dot
        margin-inline: auto
      span
        text-align: center
    +sp-view
      padding-top: 52rem
    .gallery__body
      padding-inline: var(--margin-3)
      +max-width-setting
      &:nth-of-type(2)
        padding-top: 80rem
      +sp-view
        margin-top: 40rem
        &:nth-of-type(2)
          padding-top: 46rem
          margin-top: 0rem
    .gallery__body-title
      +text(36rem, regular, $family-class)
      align-items: baseline
      +sp-view
        font-size: 23rem
      a
        margin-left: 14rem
        +sp-view
          margin-left: 11rem
      svg
        +size(20rem)
        +sp-view
          +size(16rem)
      span
        +text(14rem, medium)
        margin-left: 14rem
        +sp-view
          font-size: 12rem
          margin-left: 11rem
    .favorite-slider
      width: 100%
      margin-top: 16rem
      position: relative
      +sp-view
        width: 100vw
        position: relative
        left: calc( var(--margin-3)*-1  )
      
      .favorite-slider__button 
        position: absolute
        top: 0
        bottom: 0
        margin: auto
        +size(48rem)
        background: var(--color-black-100)
        +flex-middle
        z-index: 10
        --move: 24rem
        &--prev
          left: calc(var(--move) * -1)
        &--next
          right: calc(var(--move) * -1)
        +sp-view
          --move: calc(var(--margin-2) * -1)
          +size(40rem)
          svg
            height: 14rem
      .favorite-slider__item
        height: 313rem
        position: relative
        +sp-view
          width: 212rem
          // &:first-child
          //   padding-left: var(--margin-3)
          //   width: calc(212rem + var(--margin-3))
          //   .favorite-slider__item__num
          //     left: calc(10rem + var(--margin-3))
      .favorite-slider__item__image
        +size(100%)
      .favorite-slider__item__num
        position: absolute
        +size(32rem)
        background: var(--color-white-100)
        border-radius: 100rem
        +flex-middle
        top: 10rem
        left: 10rem
      .favorite-slider__item__num-text
        +text(16rem, regular, $family-class)
        margin-top: 3rem
        text-align: center
    // transition
    .favorite-slider__button:hover
      background: var(--color-white-100)
      svg path
        stroke: var(--color-black-100)
    // animation
    .favorite-slider__button
      transition: all 0.5s ease
      svg path
        transition: all 0.5s ease
    .cordinate
      // display: grid      
      // grid-template-columns: repeat(4, 1fr)
      // grid-template-rows: 268rem
      // grid-auto-rows: 268rem
      // gap: 17rem
      position: relative
      margin-top: 16rem
      +sp-view
        // grid-template-columns: repeat(2, 1fr)
        // grid-template-rows: 166rem
        // grid-auto-rows: 166rem
      .cordinate__item
        +size(100%)
      .cordinate__cover
        position: absolute
        +size(100%)
        top: 0
        display: block
        z-index: 2
  .faq
    padding-top: 120rem
    padding-bottom: 120rem
    +sp-view
      padding-bottom: 0rem
      padding-top: 52rem
    .parts-title
      .parts-title__main
        +side-dot
        margin-inline: auto
      span
        text-align: center
    .faq__list
      padding-inline: var(--margin-4)
      margin-top: 73rem
      +gap(bottom, 14rem)
      +max-width-setting
      +sp-view
        padding-inline: var(--margin-3)
        --gap: 12rem
        margin-top: 40rem
    // .faq__item
    .faq__item__head
      display: flex
      justify-content: space-between
      align-items: center
      padding-bottom: 11rem
      border-bottom: 1px solid var(--color-gray-200)
      width: 100%
      padding-bottom: 12rem
      +sp-view
        align-items: baseline
    .faq__item__head-num
      +text(20rem, regular, $family-class)
      margin-top: 3rem
      +sp-view
        font-size: 18rem
    .faq__item__head-text 
      +text(18rem, medium)
      margin-right: auto
      padding-left: 16rem
      +sp-view
        font-size: 12rem
        max-width: 260rem
        position: relative
        top: -3rem
        line-height: 1.6
    .faq__item__head-button
      +size(52rem)
      border-radius: 100rem
      background: var(--color-black-100)
      +flex-middle
    .faq__item__answer
      height: 0px
      overflow: hidden
    .faq__item__answer-text
      +text(12rem, medium)
      line-height: 1.8
      padding-top: 22rem
      +sp-view
        padding-bottom: 22rem
    // transition
    .faq__item__head
      .faq__item__head-button
        transition: all 0.5s ease
    //   .faq__item__head-button
    //     transition: all 0.5s ease
    //     // svg path
    //       // transition: all 0.5s ease
    // // animation
    .faq__item__head:hover
      .faq__item__head-button
        transform: scale(0.8)

    //   .faq__item__head-button
    //     background: var(--color-gray-100)
    //     svg path
    //       stroke: var(--color-black-100)

  .salons
    padding-top: 120rem
    +sp-view-over
      border-top: 1px solid var(--color-gray-200)
    +sp-view
      padding-top: 54rem
      
    .parts-title
      .parts-title__main
        +side-dot
        margin-inline: auto
      span
        text-align: center
    .salons__wrap
      margin-top: 80rem
      +gap(bottom, 80rem)
      +max-width-setting
      +sp-view-over
        padding-inline: var(--margin-3)
      +sp-view
        margin-top: 40rem
        padding-bottom: 60rem
        border-bottom: 1px solid var(--color-gray-200)
        +max-width-setting(calc(100vw - var(--margin-3) * 2))
        // --gap:
        
    // .salons__section
    .salons__section-wrap
      display: flex
      justify-content: space-between
      +sp-view
        +gap(bottom, 60rem)
    .salons__section__location
      +text(20rem, medium)
      padding-bottom: 16rem
      +sp-view
        font-size: 16rem
        padding-bottom: 10rem
    .salons__section__wrap
      display: flex
      justify-content: space-between
      align-items: flex-start
      padding-top: 40rem
      padding-bottom: 40rem
      border-top: 1px solid var(--color-gray-200)
      border-bottom: 1px solid var(--color-gray-200)
      width: 100%
      +sp-view
        +gap(bottom, 48rem)
        padding-bottom: 0rem
        border-bottom: 0rem
        padding-top: 29rem
    .salons__item
      width: fit-content
      +text(20rem, medium)
      +sp-view-over
        +gap(right, 36rem)
      +sp-view
        +gap(bottom, 16rem)
        width: 100%
      
    .salons__item__image
      width: 270rem
      +sp-view
        width: 100%
        height: 188rem
    .salons__item__description
      +sp-view
        display: grid
        grid-template-columns: 1fr 198px
        grid-template-rows: repeat(3, auto)
        gap: 0px 0px
        grid-template-areas: "name address" ". info" "link link"
    .salons__item__name
      +text(20rem, bold)
      grid-area: name
      +sp-view
        margin-top: 4rem
        font-size: 18rem
        padding-right: 16rem
    .salons__item__address
      +text(13rem, medium)
      line-height: 2
      grid-area: address
      +sp-view-over
        margin-top: 22rem
      +sp-view
        font-size: 12rem
    .salons__item__info
      +gap(bottom, 6rem)
      margin-top: 14rem
      grid-area: info
      +sp-view
        margin-top: 12rem
    .salons__item__info-item
      +gap(right, 17rem)
      +sp-view
        +gap(right, 14rem)
    .salons__item__info-item__title
      width: 60rem
      background: var(--color-black-100)
      border-radius: 4rem
      color: var(--color-white-100)
      text-align: center
      +text(10rem, medium)
      --height: 17rem
      height: var(--height)
      line-height: var(--height)
    .salons__item__info-item__text
      +text(13rem, medium)
      +sp-view
        font-size: 12rem
    .salons__item__info-item__text-notice
      bottom: 0
      display: inline-block
      font-size: 10rem
      text-align: left
      white-space: nowrap
    .salons__item__link-wrap
      +gap(right, 14rem)
      margin-top: 25rem
      grid-area: link
      +sp-view
        margin-top: 24rem
    .salons__item__link
      +text(12rem, medium)
      letter-spacing: -0.01em
      height: 40rem
      +gap(right, 10rem)
      padding-inline: 20rem
      border: 1rem solid var(--color-black-100)
      align-items: center
      border-radius: 100rem
      white-space: nowrap
      &--shop
        background: var(--color-black-100)
        color: var(--color-white-100)
        svg path
          fill: var(--color-white-100)
      +sp-view
        flex: 1
        justify-content: center

    // transition
    .salons__item__link
      transition: all 0.5s ease
      svg path
        transition: all 0.5s ease
    // animation
    .salons__item__link--map:hover
      background: var(--color-black-100)
      color: var(--color-white-100)
      svg path
        fill: var(--color-white-100)
    .salons__item__link--shop:hover
      background: var(--color-white-100)
      color: var(--color-black-100)
      svg path
        fill: var(--color-black-100)
  .banners
    padding-inline: var(--margin-3)
    width: 100%
    padding-top: 130rem
    padding-bottom: 140rem
    display: flex
    justify-content: space-between
    +max-width-setting
    +sp-view-over
      +gap(right,14rem)
    +sp-view
      +gap(bottom, 15rem)
      padding-top: 52rem
    .banners__item
      overflow: hidden
    .banners__item__image
      height: auto
      width: 100%
      +sp-view
        height: 100%
    .banners__item:hover
      .banners__item__image
        transform: scale(1.05)
    .banners__item__image
      transition: all 0.5s ease
      